<template>
    <div class="settings-manager">
        <h2 class="center text-center">{{ translate('hear.settingsManager.settingsManagerTitle') }}</h2>

        <div class="flex-column l:flex-row flex-center flex-middle l:flex-stretch gap-xl">
            <div class="load-settings max-width-300">
                <h4>{{ translate('hear.settingsManager.settingsManagerLoadTitle') }}</h4>
                <base-form
                    action="#"
                    method="post"
                    @form:submit-success="loadSettingsByState"
                    v-model="loadSettingsFormData"
                    :config="loadSettingsFormConfig"></base-form>
            </div>
            <we-horizontal-ruler class="l:hidden"  color="gray-2" margin="xs"/>
            <div class="save-settings max-width-300">
                <h4>{{ translate('hear.settingsManager.settingsManagerSaveTitle') }}</h4>
                <base-form
                    action="hear/settings"
                    method="post"
                    v-model="saveSettingsFormData"
                    @form:submit-success="settingPresetSaved"
                    :config="createSettingsFormConfig"></base-form>
            </div>
        </div>

    </div>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import {computed} from 'vue';
import WeHorizontalRuler from "@/client/applications/hear/components/base/frameworks/we/html/WeHorizontalRuler";

export default {
    components: {WeHorizontalRuler},
    props: {
        subject         : {
            type   : String,
            default: false,
        },
        settingsGetter  : {
            type   : String,
            default: false,
        },
        settingsMutation: {
            type   : String,
            default: false,
        },
    },
    setup(props) {
        let {asyncOps} = asyncOperations(props);

        return {asyncOps};
    },
    data   : function () {
        return {
            saveSettingsFormData    : {
                test    : this.subject,
                settings: computed(() => {
                    return JSON.stringify(this.$store.getters[this.settingsGetter])
                }),
                label   : ''
            },
            loadSettingsFormData    : {
                preset: null
            },
            createSettingsFormConfig: {
                fields: {
                    label   : {
                        name       : 'label',
                        label      : 'hear.settingsManager.createForm.labelLabel',
                        placeholder: '',
                        validation : {
                            required: {},
                        }
                    },
                    test    : {
                        name       : 'test',
                        label      : 'hear.settingsManager.createForm.testLabel',
                        type: 'hidden',
                        placeholder: '',
                        validation : {
                            required: {},
                        }
                    },
                    settings: {
                        name       : 'settings',
                        label      : 'hear.settingsManager.createForm.settingsLabel',
                        disabled   : true,
                        type: 'hidden',
                        placeholder: '',
                        validation : {
                            required: {},
                        }
                    },
                }
            },
            loadSettingsFormConfig  : {
                fields      : {
                    preset: {
                        name                        : 'preset',
                        label                       : 'hear.settingsManager.loadForm.presetLabel',
                        type                        : 'select2',
                        placeholder                 : '',
                        asyncListSource             : 'hear/settings/all/simple',
                        asyncDataTextFilterParamName: 'q',
                        translateList               : false,
                        asyncListData               : {
                            test: this.subject,
                        },
                        validation                  : {
                            required: {},
                        }
                    },
                },
                formHandling: {
                    clientOnly: true,
                }
            }
        };
    },
    methods: {
        async loadSettingsByState(submission) {
            let presetId = submission.formData.preset;

            let settings = await this.performWithUi(async () => {
                let result = await this.asyncOps.asyncCall('hear/settings/' + presetId, {});
                return result?.data?.item?.settings;
            }, false);
            // fetch the settings


            if ( ! settings) {
                return;
            }

            try {
                settings = JSON.parse(settings);
            } catch (e) {
                this.$s.ui.notification('core.errorGeneric', 'error');
                return;
            }
            this.$store.commit(this.settingsMutation, settings);
            this.$s.ui.notification('hear.settingsManager.loadSuccess', 'success');

            this.$emit('settingsManager:settingsLoaded');
        },
        settingPresetSaved() {},
    },


}
</script>

<style scoped lang="scss">

</style>
