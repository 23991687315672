import asyncFactory from '@/client/extensions/modules/asyncComponentFactory.js'


export default [
    {
        path: '/admin/user',
        component: asyncFactory('views/admin/user/List.vue'),
        name: 'admin-user',
        meta : {
            layout : 'adminDashboard',
            requiresAdmin : true
        },
    },
    {
        path: '/admin/user/create',
        component: asyncFactory('views/admin/user/Create.vue'),
        name: 'admin-user-create',
        meta : {
            layout : 'adminDashboard',
            requiresAdmin : true
        },
    },
    {
        path: '/admin/user/edit/:userId',
        component: asyncFactory('views/admin/user/Edit.vue'),
        name: 'admin-user-edit',
        props: true,
        meta : {
            layout : 'adminDashboard',
            requiresAdmin : true
        },
    },



];
