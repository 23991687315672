let module = false;

let context = require.context('@/', true, /\/overrides\/client\/router\/index\.js/);
context.keys().forEach(key => {
  if ('./overrides/client/router/index.js' === key) {
    module = context(key);
  }
});
if ( ! module) {
  module = require("@/client/router/router.js");
}

export default module.default;
