import {watchEffect, computed } from 'vue';

/**
 * When a route meta has requiresAdmin, will redirect guests based on store/auth module
 * Will hang and wait, if the auth status is not determined.
 *
 * @param router
 * @returns {{handler: handler, routerMethod: string}}
 */
export default (router) => {
    let isAuthWorking = computed(() => {
        return router.$store.getters['user/isFetchingProfile'] ||
               router.$store.getters['user/isFetchingJwt']
    });

    return {
        routerMethod: 'beforeEach',
        handler: async (to, from) => {
            let redirect;

            let getters = router.$store.getters;

            if ( ! to.meta || ! to.meta.requiresAdmin) {
                return true;
            }

            // if user is not authenticated, they are not an admin for sure
            if ( ! router.$store.getters['user/isAuthenticated']) {
                redirect =  {name: 'unauthorised'};
                router.push(redirect);
                return false;
            }

            // if user profile is not fetched,
            if (router.$store.getters['user/profile'].email !== 'undefined') {

                //fetch it if it is not in progress of being fetch
                if (  ! isAuthWorking.value) {
                    router.$store.dispatch('user/updateUserProfile');
                }

                // wait for fetching to complete
                await new Promise((resolve) => {
                    // todo: this has bad safeguards. if stuff goes wrong - it may get stuck. add a timeout maybe, and/or some spinner?
                    watchEffect( () => {
                        let profileFetched = typeof router.$store.getters['user/profile'].email !== 'undefined';

                        if (profileFetched) {
                            resolve();
                        }
                    });
                });

            }



            // now check to see if the fetched profile says we are admin or not
            if ( ! router.$store.getters['user/isAdmin']) {
                redirect =  {name: 'unauthorised'};
                router.push(redirect);
                return false;
            }

            return true;

        }
    }
}
