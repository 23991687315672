<template>
    <div class="test-a">
        <div v-show="debug">
            <h5>debug</h5>
            <button-group class="">

                <form-button class=""
                             @click="playingNoise ? stopNoise() : playNoise(true)"
                             :auto-translate="false"
                             text="toggle noise">
                </form-button>
                <form-button :auto-translate="false"
                             text="play current digits"
                             @click="playDigitRound(true, false, false)"></form-button>
                <form-button :auto-translate="false"
                             text="toggle extra info"
                             @click="showDebugBar = ! showDebugBar">
                </form-button>
                <form-button :auto-translate="false"
                             text="reset test"
                             @click="reset()">
                </form-button>
            </button-group>
            <div class="info-container">
                <strong>עצמת רעש
                    :</strong><span>{{ Math.round(getVolumeDbByPercent(noiseVolume)) }} -> {{
                    noiseVolume * 100
                }}%</span>
                <br>
                <strong>עצמת ספרות
                    :</strong><span>{{ Math.round(getVolumeDbByPercent(digitVolume)) }} -> {{
                    digitVolume * 100
                }}%</span>
                <br>
                <strong>יתכנו סטיות קלות ביותר בעקבות תרגום לאחוזים</strong>
                <br>
                <strong>ספרות נוכחיות: </strong><span style="direction: ltr;">{{ digit1 }}{{ digit2 }}{{
                    digit3
                }}</span>
            </div>
            <div class="audio-container" :style="audioContainerCss">

                <div>בקרי אודיו. מימין לשמאל - הספרות, ולבסוף הרעש</div>
                <custom-audio ref="audioDigit1"
                              :showPlayer="true"
                              :volume="digitVolume"
                              :source="audio1Src"
                ></custom-audio>
                <custom-audio ref="audioDigit2"
                              :showPlayer="true"
                              :volume="digitVolume"
                              :source="audio2Src"
                ></custom-audio>
                <custom-audio ref="audioDigit3"
                              :showPlayer="true"
                              :volume="digitVolume"
                              :source="audio3Src"
                ></custom-audio>
                <!-- TODO: probably wrong to /2 -->
                <custom-audio ref="audioNoise"
                              :showPlayer="true"
                              :loop="true"
                              :volume="noiseVolume "
                              :source="audioRootUrl + 'noise/' + noiseFile"
                ></custom-audio>
            </div>


            <div v-if="debug && playingNoise" class="noise-bar">debug: playing noise</div>


            <div v-if="debug && showDebugBar"
                 style="direction:ltr;text-align:left;position: fixed;top:100px;right:0;width:350px;height: 80vh;overflow-y:scroll;">
                <div>Debug Bar</div>
                <div>
                    <!-- the {{$debug}} needs to be "stuck" to the start of the line for readability -->
                    <pre>
{{ $data }}
                </pre>
                </div>
            </div>
        </div>
        <h1 class="center text-center margin-bottom-remove">{{ translate('hear.DINTest.testTitle') }}</h1>

        <div v-if="stage === 'SUBJECT_DETAILS'" class="subject-details">
            <subject-details @subject-details-saved="subjectDetailsSaved()"
                             :notifyAboutSuccess="false"
                             :submitButtonText="'hear.DINTest.detailsContinue'">
            </subject-details>
        </div>

        <div v-if="stage === 'INSTRUCTIONS'" class="instructions">
            <h3 class="text-center">{{ translate('hear.DINTest.instructionsTitle') }}</h3>
            <p class="text-center">
                {{ translate('hear.DINTest.explain1') }}
            </p>
            <p class="text-center">
                {{ translate('hear.DINTest.explain2') }}
            </p>
            <p class="text-center">
                {{ translate('hear.DINTest.explain3') }}
            </p>
            <p class="text-center">
                {{ translate('hear.DINTest.explain4') }}
                <br>
                {{ translate('hear.DINTest.explain5') }}
            </p>
            <form-button
                class="margin-auto-horizontal margin-xl-top"
                text="hear.DINTest.explainCta"
                @click="stage = 'CALIBRATION'"></form-button>
        </div>

        <div v-if="stage === 'CALIBRATION'" class="calibration container-small text-center">
            <h3>{{ translate('hear.DINTest.calibrateTitle') }}</h3>
            <p>
                {{ translate('hear.DINTest.calibrateExplain1') }}
                <br>
                {{ translate('hear.DINTest.calibrateExplain2') }}
            </p>
            <p>
                {{ translate('hear.DINTest.calibrateExplain3') }}
            </p>
            <p>
                {{ translate('hear.DINTest.calibrateExplain4') }}
            </p>
            <button-group class="flex-center gap-m margin-l-top">
                <form-button @click="!currentlyPlaying && playCalibrationRound()"
                             text="hear.DINTest.calibratePlayCta">
                </form-button>
            </button-group>
            <button-group class="flex-center gap-m margin-l-top">
                <form-button
                    text="hear.DINTest.calibrateContinue"
                    @click="stage = 'EXAMPLE_ATTEMPTS'; resetUserInput()">
                </form-button>
            </button-group>

        </div>

        <div v-if="stage === 'EXAMPLE_ATTEMPTS'" class="stage-example-attempts-text">
            <h3 class="enter margin-auto-horizontal margin-m-bottom  text-center">
                {{ translate('hear.DINTest.exampleTitle') }}</h3>
            <p class="center text-center margin-m-bottom">
                {{ translate('hear.DINTest.exampleExplain') }}
            </p>
            <button-group class="center text-center flex flex-center flex-wrap margin-l-bottom">
                <form-button @click="playExample"
                             v-if="exampleAttempts.showInitialButton"
                             text="hear.DINTest.examplePlayCta"
                ></form-button>
                <form-button v-if="showExampleAttemptRepeatButton"
                             text="hear.DINTest.exampleRepeatCta"
                             @click="!currentlyPlaying && onRepeatHearing()"></form-button>
                <form-button v-if=" ! exampleAttempts.showInitialButton"
                             text="hear.DINTest.exampleContinueCta"
                             @click="stopNoise(); showPreTestModal()"></form-button>
            </button-group>
        </div>

        <div v-if="stage === 'TEST'" class="test text-center margin">
            <p class="text-center">{{ translate('hear.DINTest.testExplain') }}</p>
        </div>

        <div v-if="showInputUI" class="test-a-input-ui margin center">
            <div class="message-container container container-xs margin-auto" v-if="stage === 'EXAMPLE_ATTEMPTS'">
                <alert v-if="message"
                       class="margin-auto-horizontal"
                       :content="message"
                       :title="false"
                       close-type="none"
                       theme="inverse">
                </alert>
            </div>
            <div class="answer-display padding-small text-center" style="min-height:92px;">
                <div class="inner "  v-show="isReplaying">
                    <h3 class="margin-remove">הספרות שהושמעו:</h3>
                    <div class=" text-2xl c-lead border ltr flex-center gap-l" >
                    <span v-for="index of maxUserInputLength"
                          class="block padding-s">
                        {{getAnswerDigitByIndex(index)}}
                    </span>
                    </div>
                </div> <!-- v-show="isReplaying"-->

            </div>

            <div class="container center ">
                <div class="play-indicator-field  flex-center gap-l margin-auto-horizontal margin-m-bottom">
                      <span v-for="indicatorBool of playIndicators" class="indicator-slot">
                          <icon icon="rss" v-show="indicatorBool"></icon>
                      </span>
                </div>

                <div class="user-input-field form-input margin-auto border-thin padding-s flex-center gap-l">
                    <span class="input-content block relative" v-for="i of maxUserInputLength">
                        {{ getUserInputChar(i - 1) }}
                        <span class="digit-feedback absolute block" v-if="showDigitFeedback">
                          <span v-if="userInputAccuracy[i-1] === null"
                                class="digit-feedback__inner bg-gray-2 block"></span>
                          <span v-if="userInputAccuracy[i-1] === false"
                                class="digit-feedback__inner bg-error  block"></span>
                          <span v-if="userInputAccuracy[i-1] === true"
                                class="digit-feedback__inner bg-success block"></span>
                        </span>

                    </span>
                </div>

                <div class="digit-input-ui">
                    <span v-for="(char, index) in inputChars"
                          :key="char"
                          :style="{order: index*10}"
                          class="badge input-ui-char background-inverse radius-round border-none"
                          @click="userInputGiven(char)">{{ char }}</span>
                    <span class="badge input-ui-char user-input-reset bg-danger radius-round border-none"
                          @click="deleteLastUserInput">
                         <icon icon="x" :size="1.5"></icon>
                    </span>

                    <span class="badge input-ui-char bg-lead user-input-submit radius-round border-none"
                          @click="userInputSubmitted">
                         <icon icon="check" size="1.5"></icon>
                    </span>

                </div>
            </div>

            <div class="margin-m-vertical margin-auto-horizontal container container-xs relative"
                 style="min-height: 62px;"
                 v-if="settings.stepFeedback && stage !== 'EXAMPLE_ATTEMPTS'">
                <alert
                    v-show="showingStepFeedback"
                    class="margin-auto-horizontal"
                    :title="false"
                    :close-type="'none'"
                    :theme="stepFeedbackSuccess ? 'success' : 'warning'">
                    <icon-text
                        :icon="stepFeedbackSuccess ? 'check' : 'x'"
                        :text="stepFeedbackSuccess ? 'hear.DINTest.correctAnswer' : 'hear.DINTest.wrongAnswer'"></icon-text>
                </alert>
            </div>
            <div class="next-step-container flex flex-middle flex-center"
                 style="min-height: 40px;"
                 v-if=" ! roundAutoPlay && stage === 'TEST'">
                <form-button text="hear.DINTest.playNextRoundCta"
                             :disabled=" ! isRoundComplete"
                             :theme="isRoundComplete ? 'lead': 'gray-2'"
                             @click="runNextRound"
                             icon-end="chevron-inline-end"></form-button>
            </div>
        </div>

        <div v-if="stage === 'RESULTS'" class="test-end">
            <h3 class="center text-center">{{ translate('hear.DINTest.resultTitle') }}</h3>
            <h2 class="center text-center">
                <span>{{ translate('hear.DINTest.resultThreshold') }}:</span><br>
                <strong
                    style="direction: ltr;text-align: left;unicode-bidi: bidi-override;">{{
                        Math.round(finalRatio)
                    }}</strong>
            </h2>

            <button-group class="center margin-auto flex-center">
                <form-button theme="inverse"
                             type="secondary"
                             @click="reset"
                             text="hear.DINTest.resultNewTestCta">
                </form-button>
            </button-group>
            <p class="margin-2xl-top margin-m-bottom text-center center">
                {{ translate('hear.DINTest.resultExportTitle') }}
            </p>
            <button-group class="container  center flex-center text-center margin-auto-horizontal gap-m">
                <form-button class="button-export"
                             @click="exportCsv"
                             text="hear.DINTest.resultExportCsvCta"></form-button>
                <form-button class="button-export"
                             text="hear.DINTest.resultExportHtmlCta"
                             @click="showResultsHtml = true"
                ></form-button>
            </button-group>
            <div class="results-log-html" v-show="showResultsHtml">
                <h4>{{ translate('hear.DINTest.resultSubjectDetails') }}</h4>
                <ul>
                    {{ subjectDetailsString }}
                </ul>
                <h4>{{ translate('hear.DINTest.resultThreshold') }}: {{ finalRatio }}</h4>
                <h4>{{ translate('hear.DINTest.resultCourse') }}</h4>

                <table class="table" style="direction: ltr; text-align: left;">
                    <thead>
                    <tr>
                        <th v-for="(value, name) of testState.stepLogs[0]" :key="'htmlResultColName_'+name">{{
                                name
                            }}
                        </th>
                    </tr>
                    </thead>


                    <tbody>
                    <tr v-for="(step, index) of testState.stepLogs" :key="index" :step="step">
                        <td v-for="(value, name) of step" :key="index+'_'+name">{{ value }}</td>
                    </tr>
                    </tbody>
                </table>


                <a v-show="false" ref="csvLinkDownload"></a>
            </div>
        </div>

        <div class="quick-reset margin-2xl-top" v-if="stage !== 'SUBJECT_DETAILS'">
            <hr class=""/>
            <div class="flex-center width-expand margin-xl-top">
                <form-button @click="resetButtonHandler"
                             text="hear.DINTest.resultExit"></form-button>
            </div>
        </div>
        <!-- start test modal -->
        <!--
        <div id="modal-example " ref="preTestModal" uk-modal>
            <div class="uk-modal-dialog uk-modal-body">
                <h2 class="uk-modal-title">התחלת מבדק</h2>
                <p>בלחיצה על כפתור ההמשך, המבדק יתחיל. הספרות יושמעו מייד.</p>
                <p class="uk-text-right">
                    <button class="uk-button uk-button-primary"
                            @click="closePreTestModel(); stage = 'TEST'"
                            type="button">התחל מבדק
                    </button>
                </p>
            </div>
        </div>-->

        <modal ref="preTestModal" title="hear.DINTest.startModalTitle"
               @modal:closed="handlePretestApproved">
            <template #default>
                <p>{{ translate('hear.DINTest.startModalContent') }}</p>
            </template>
            <template #footer="{modal}">
                <form-button type="primary"
                             theme="lead"
                             @click="modal.close()"
                             text="hear.DINTest.stateModalCta"
                ></form-button>
            </template>
        </modal>
        <!-- digit preloader -->
        <div class="uk-container uk-flex uk-flex-center uk-flex-middle" v-if="loadedDigits<10">
            <span class=" uk-margin-small-left uk-margin-small-right" uk-spinner></span>
            <span class=" uk-margin-small-left uk-margin-small-right ">טוען אודיו</span>
            <progress class="uk-progress uk-margin-remove uk-margin-small-left uk-margin-small-right"
                      :value="loadedDigits"
                      max="10"></progress>
        </div>


    </div>
</template>

<script>
import SubjectDetails from "@/client/applications/hear/components/SubjectDetails.vue";

import CustomAudio from "@/client/applications/hear/components/AudioHowler.vue";

export default {
    layout    : 'default',
    components: {
        SubjectDetails, CustomAudio
    },
    props     : {},
    data      : function () {
        return {
            gotAccess1          : false,
            gotAccess2          : false,
            gotAccess3          : false,
            debug               : true,
            showDebugBar        : false,
            loadedDigits        : 0,
            stage               : 'SUBJECT_DETAILS',
            playing             : false,
            digit1              : 1,
            digit2              : 2,
            digit3              : 3,
            playIndicator1      : false,
            playIndicator2      : false,
            playIndicator3      : false,
            digitVolume         : 0.5,
            noiseVolume         : 0.5,
            audioRootUrl        : '/audio/',
            noiseFile           : 'noise.wav',
            stagesWithInputUi   : ['EXAMPLE_ATTEMPTS', 'TEST'],
            lastUserInput       : '',
            userInput           : '',
            defaultUserInputChar: '-',
            inputChars          : [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
            message             : '',
            takingInput         : false,
            maxUserInputLength  : this.$store.getters['testA/settings']['numDigits'],
            playingNoise        : false,
            exampleAttempts     : {
                showInitialButton: true,
                attempts         : 0,
                hasSucceeded     : false,
            },
            testState           : {
                isRunning   : false,
                currentIndex: 0,
                stepLogs    : []
            },
            finalPnr            : 0,
            showResultsHtml     : false,
            appleAudioEnabled   : false,
            currentlyPlaying    : false,
            showingStepFeedback : false,
            stepFeedbackSuccess : false,
            stepFeedbackDuration: this.$store.getters['testA/settings']['setFeedBackDuration'],
            stepFeedbackTimeout : null,
            showDigitFeedback: this.$store.getters['testA/settings']['digitFeedback'],
            roundAutoPlay: this.$store.getters['testA/settings']['nextRoundAutoPlay'],
            isRoundComplete: false,
            useReplayFeedback: this.$store.getters['testA/settings']['stepReplayFeedback'],
            lastUserInputSubmitHandled : false,
            isReplaying: false,
            hasMountedOnce : false,
        };
    },
    computed  : {
        showDigitFeedback() {
            if (this.isReplaying) {
                return true;
            }
            return this.$store.getters['testA/settings']['digitFeedback'];
        },
        digitInterval    : function () {
            return this.$store.getters['testA/settings'].digitInterval;
        },
        audioContainerCss: function () {
            return {display: (this.debug ? 'block' : 'none')}
        },
        settings         : {
            get: function () {
                return this.$store.getters['testA/settings'];
            },
            set: function () {
                throw new Error('cant set settings via computed property in testA');
            }
        },
        showInputUI() {
            return this.stagesWithInputUi.includes(this.stage);
        },
        userInput1() {
            return this.getUserInputChar(0);
        },
        userInput2() {
            return this.getUserInputChar(1);
        },
        userInput3() {
            return this.getUserInputChar(2);
        },
        userInputLength() {
            return this.userInput.length;
        },
        answer() {
            let result = '';
            for (let i = 1; i <= this.maxUserInputLength; i++) {
                result = result + String(this['digit' + i]);
            }
            return result;
        },
        showExampleAttemptRepeatButton: function () {
            return (
                ! this.exampleAttempts.showInitialButton
            )
        },
        isInitialSteps                : function () {
            return this.testState.currentIndex <= this.getSetting('initialRoundCount');
        },
        stepSize                      : function () {
            if (this.isInitialSteps) {
                return this.getSetting('initialRoundStep');
            } else {
                return this.getSetting('roundStep');
            }
        },
        finalRatio                    : function () {
            if (this.testState.stepLogs.length < 1) {
                return 0;
            }

            let total      = this.testState.stepLogs.length,
                start      = total - this.getSetting('viableRounds'),
                end        = total,
                steps      = this.testState.stepLogs.slice(start, end),
                count      = +end - start,
                totalRatio = 0;
            steps.forEach(function (step) {
                totalRatio = totalRatio + step.ratio;
            });

            return totalRatio / count;
        },
        subjectDetails() {
            return this.$store.getters['subject/subjectData']
        },
        subjectDetailsString() {
            return Object.values(this.subjectDetails).join(', ');
        },
        subjectDetailsStringForMachine() {
            return Object.values(this.subjectDetails).join('_');
        },
        audio1Src() {
            if (this.getSetting('pureTone')) {
                return this.audioRootUrl + 'digits/' + 'pure' + '.wav'
            }
            return this.audioRootUrl + 'digits/' + this.digit1 + '.wav'
        },
        audio2Src() {
            if (this.getSetting('pureTone')) {
                return this.audioRootUrl + 'digits/' + 'pure' + '.wav'
            }
            return this.audioRootUrl + 'digits/' + this.digit2 + '.wav'
        },
        audio3Src() {
            if (this.getSetting('pureTone')) {
                return this.audioRootUrl + 'digits/' + 'pure' + '.wav'
            }
            return this.audioRootUrl + 'digits/' + this.digit3 + '.wav'
        },
        playIndicators() {
            let result = [];
            for (let i = 1; i <= this.maxUserInputLength; i++) {
                result.push(this['playIndicator' + i]);
            }
            return result;
        },
        userInputAccuracy() {
            let result     = [];
            let splitInput = this.userInput.split('');

            for (let i = 0; i <= this.maxUserInputLength - 1; i++) {
                // input is missing
                if (typeof splitInput[i] !== 'string') {
                    result[i] = null;
                    continue;
                }
                let digitKey = 'digit' + (+i + 1)

                result[i] = parseInt(splitInput[i]) === parseInt(this[digitKey]);
            }

            return result;
        }
    },
    methods   : {
        async getAudioAccess(audioNum) {
            try {
                await this.$refs['audioDigit' + audioNum].play();
                this['gotAccess' + audioNum] = true;
            } catch (e) {
            }

        },
        getUserInputChar(index) {
            let exploded = this.userInput.split('');
            if (typeof exploded[index] === 'undefined') {
                return this.defaultUserInputChar;
            } else {
                return exploded[index];
            }
        },
        getSetting(key) {
            return (typeof this.settings[key] !== 'undefined' ? this.settings[key] : null);
        },
        preloadAudio() {
            this.digit1 = 'pure';

            for (let i = -1; i < 10; i++) {
                setTimeout(() => {
                    this.digit1       = i;
                    this.loadedDigits = i + 1;
                }, i * 150);
            }
        },
        subjectDetailsSaved() {
            this.stage = 'INSTRUCTIONS';
        },
        getRandomDigit() {
            return Math.floor(Math.random() * 10);
        },
        randomiseDigits() {
            let digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

            for (let i = digits.length - 1; i > 0; i--) {
                const j    = Math.floor(Math.random() * i);
                const temp = digits[i];
                digits[i]  = digits[j];
                digits[j]  = temp;
            }

            this.digit1 = digits[0];
            this.digit2 = digits[1];
            this.digit3 = digits[2];
        },
        async playCalibrationRound() {
            this.randomiseDigits();
            await this.playDigitRound();
            return true;

        },
        async playCurrentDigits() {

        },
        async playDigitRound(withDelay = true, resetUserInput = true, randomiseDigits = true) {
            this.currentlyPlaying = true;
            if (resetUserInput) {
                this.resetUserInput();
            }

            if (randomiseDigits) {
                this.randomiseDigits();
            }

            // rest audios. we can start playing after the first is loaded. play time is long enough, assume others will load in time
            try {
                await this.$refs.audioDigit1.stopReset();
                await this.$refs.audioDigit2.stopReset();
                await this.$refs.audioDigit3.stopReset();
            } catch (e) {

            }


            for (let i = 1; i <= this.maxUserInputLength; i++) {
                this['playIndicator' + i] = true;
                await this.playAudioDigit(i, withDelay);
                this['playIndicator' + i] = false;
            }

            this.currentlyPlaying = false;
            return true;
        },
        async playAudioDigit(id = 0, withDelay = true) {
            try {
                await this.$refs['audioDigit' + id].play();
            } catch (e) {
                console.log(e);
            }

            await new Promise((resolve, reject) => {
                if ( ! withDelay) {
                    return resolve(true);
                }

                setTimeout(() => {
                    resolve(true)
                }, this.digitInterval);
            });
        },
        playNoise(force = false) {
            if (force || ! this.debug) {

                try {
                    this.$refs.audioNoise.play();
                } catch (e) {

                }
            }

            this.playingNoise = true;
        },
        stopNoise() {
            try {
                this.$refs.audioNoise.stop();
            } catch (e) {

            }

            this.playingNoise = false;
        },
        resetUserInput() {
            this.lastUserInput = this.userInput.split('').join('');
            this.userInput = '';
        },
        deleteLastUserInput() {
            this.userInput = this.userInput.slice(0, -1);
        },
        userInputGiven(char) {
            if (this.userInputLength >= this.maxUserInputLength) {
                return false;
            }

            this.userInput += char;

            return true;
        },
        startTest() {
            let digitVolumeDb      = this.getSetting('baseVolumeAbsolute') + this.getSetting('baseRatio');
            let digitVolumePercent = this.getVolumePercentByDb(digitVolumeDb);


            this.digitVolume         = digitVolumePercent;
            this.testState.isRunning = true;
            this.message             = '';
            this.resetUserInput();

            // set digit volume
            this.playDigitRound();
        },
        getVolumePercentByDb(input) {

            // 100
            let basePercent = this.getSetting('baseNoiseVolume') / 100,
                baseDb      = this.getSetting('baseVolumeAbsolute'), //100 db
                diff        = input - baseDb;
            // 100
            let result      = Math.pow(10, diff / 20) * basePercent;
            //console.log('get percent by db', input, result);
            return result;
            //  return result * 100; // TODO probably /100 or no change

            //  let diff = input - 100;
            //  let result = Math.pow(10, diff / 20);
            // return result * 100;
        },
        getVolumeDbByPercent(input) {

            // 100
            let basePercent = this.getSetting('baseNoiseVolume') / 100,
                // 100
                baseDb      = this.getSetting('baseVolumeAbsolute');

            let result = 20 * Math.log10(input / basePercent) + baseDb; // todo: seems correct
            //     console.log('get db by percent', input, result);
            return result;
            //  return 20 * Math.log10(input) + 100; // todo: seems correct
        },
        getNextVolume(isSuccess = true, returnType = null) {
            let currentDb     = this.getVolumeDbByPercent(this.digitVolume),
                maxDb         = this.getSetting('baseVolumeAbsolute') + this.getSetting('maximumRatio'),
                minDb         = this.getSetting('baseVolumeAbsolute') + this.getSetting('minimumRatio'),
                stepDirection = isSuccess ? -1 : 1,
                next          = {
                    percent: 0,
                    db     : 0
                };

            // calculate next step;
            next.db      = currentDb + (this.stepSize * stepDirection);
            next.percent = this.getVolumePercentByDb(next.db);

            // enforce max
            if (next.db > maxDb) {
                next.db      = maxDb;
                next.percent = this.getVolumePercentByDb(maxDb) / 100;
            }

            // enforce min
            if (next.db < minDb) {
                next.db      = minDb;
                next.percent = this.getVolumePercentByDb(minDb) / 100;
            }

            // return the result
            if (returnType === 'percent') {
                return next.percent;
            }

            if (returnType === 'db') {
                return next.db;
            }

            return next;
        },
        handleUserInputExampleAttempts() {
            if (this.userInput === this.answer) {
                this.exampleAttempts.hasSucceeded = true;
                this.message                      = 'hear.DINTest.correctAnswer';
            } else {
                this.message = 'hear.DINTest.wrongAnswer';
            }
        },
        async userInputSubmitted() {
            let isCorrect;

            // only do anything if this is the last required digit (no more no less)
            if (this.userInputLength < this.maxUserInputLength) {
                return;
            }




            // for test attempt - handle in dadicated function
            if (this.stage === 'EXAMPLE_ATTEMPTS') {
                this.handleUserInputExampleAttempts();
                return;
            }

            // if this is not the example attempts, we only listen here if the test is running
            if ( ! this.testState.isRunning) {
                return;
            }

            if (this.lastUserInputSubmitHandled) {
                return;
            }

            this.lastUserInputSubmitHandled = true;

            // handle user input in test. log it
            isCorrect = this.userInput === this.answer;

            let playedChars = [];

            for (let i = 1; i <= this.maxUserInputLength; i++) {
                playedChars.push(String(this['digit' + i]));
            }

            this.testState.stepLogs.push({
                                             'index'        : this.testState.currentIndex,
                                             'chars_played' : playedChars.join(''),
                                             'user_input'   : this.userInput,
                                             'is_correct'   : isCorrect,
                                             'db_level'     : this.getVolumeDbByPercent(this.digitVolume),
                                             'ratio'        : (this.getSetting('baseVolumeAbsolute') - this.getVolumeDbByPercent(this.digitVolume)) * -1, // currentNoiseVolumeDV - currentDigitVolumeDB
                                             'percent_level': this.digitVolume * 100,
                                             'is_initial'   : this.isInitialSteps
                                         });

            // check if we are done
            if (this.testState.currentIndex + 1 >= this.getSetting('totalRounds')) {
                try {
                    this.$refs.audioNoise.stop();
                } catch (e) {

                }
                this.stage = 'RESULTS';
                return;
            }

            // not done. adjust volume and play next
            this.testState.currentIndex++;
            this.resetUserInput();
            this.digitVolume = this.getNextVolume(isCorrect, 'percent');

            // show feedback
            if (this.settings.stepFeedback && isCorrect) {
                this.showStepFeedback(true);
            }

            if (this.useReplayFeedback) {
                this.showStepFeedbackReplay();
            }

            if (this.settings.stepFeedback && ! isCorrect) {
                this.showStepFeedback(false);
            }

            this.isRoundComplete = true;


            if (this.roundAutoPlay) {
                setTimeout(() => {
                    this.runNextRound();
                }, this.getSetting('roundInterval'));
            }

        },
        showStepFeedback(isCorrect) {
            clearTimeout(this.stepFeedbackTimeout);
            this.stepFeedbackSuccess = isCorrect;
            this.showingStepFeedback = true;
            this.stepFeedbackTimeout = setTimeout(() => {
                this.showingStepFeedback = false;
            }, this.stepFeedbackDuration);
        },
        toggleNoise() {
            if (this.playingNoise) {
                this.stopNoise();
            } else {
                this.playNoise();
            }
        },
        showPreTestModal() {
            this.$refs.preTestModal.open();
        },
        closePreTestModel() {
            try {
                this.$refs.preTestModal.close();
            } catch (e) {

            }
        },
        exportCsv() {
            // create CSV string
            let csv = 'data:text/csv;charset=utf-8,',
                fileName;

            csv += Object.keys(this.testState.stepLogs[0]).join(',') + "\n";

            this.testState.stepLogs.forEach((step, index) => {
                csv += Object.values(step).join(',');
                if (index < this.testState.stepLogs.length) {
                    csv += "\n"
                }
            });

            fileName = this.subjectDetailsStringForMachine + '_' + new Date().toString().replaceAll(' ', '_');

            this.$refs.csvLinkDownload.setAttribute("href", encodeURI(csv));
            this.$refs.csvLinkDownload.setAttribute("download", fileName + ".csv");
            this.$refs.csvLinkDownload.click(); // This will download the data file named "my_data.csv".
        },
        reset() {
            let exampleVolumeDb      = this.getSetting('baseVolumeAbsolute') + this.getSetting('exampleRatio');
            let exampleVolumePercent = this.getVolumePercentByDb(exampleVolumeDb);

            this.resetUserInput();
            this.debug = this.getSetting('debugMode') == 1;

            if (this.getSetting('skipToTest')) {
                this.stage = 'TEST';
            } else {
                this.stage = 'SUBJECT_DETAILS';
            }

            this.noiseVolume                       = this.getSetting('baseNoiseVolume') / 100;
            this.digitVolume                       = exampleVolumePercent;
            this.message                           = '';
            this.takingInput                       = false;
            this.playing                           = false;
            this.playIndicator1                    = false;
            this.playIndicator2                    = false;
            this.playIndicator3                    = false;
            this.showResultsHtml                   = false;
            this.exampleAttempts.showInitialButton = true;
            this.exampleAttempts.attempts          = 0;
            this.exampleAttempts.hasSucceeded      = false;
            this.testState.isRunning               = false;
            this.testState.currentIndex            = 0;
            this.testState.stepLogs                = [];

            this.$store.commit('subject/resetSubject');


            /*
             this.testState.currentIndex = 4;
             this.testState.stepLogs = [
             {
             "index": 0,
             "chars_played": "729",
             "user_input": "111",
             "is_correct": false,
             "db_level": 50,
             "ratio": 0,
             "percent_level": 25,
             "is_initial": true
             },
             {
             "index": 1,
             "chars_played": "578",
             "user_input": "999",
             "is_correct": false,
             "db_level": 60,
             "ratio": -10,
             "percent_level": 30,
             "is_initial": true
             },
             {
             "index": 2,
             "chars_played": "924",
             "user_input": "999",
             "is_correct": false,
             "db_level": 70,
             "ratio": -20,
             "percent_level": 35,
             "is_initial": true
             },
             {
             "index": 3,
             "chars_played": "465",
             "user_input": "999",
             "is_correct": false,
             "db_level": 75,
             "ratio": -25,
             "percent_level": 37.5,
             "is_initial": true
             },
             {
             "index": 4,
             "chars_played": "397",
             "user_input": "999",
             "is_correct": false,
             "db_level": 75,
             "ratio": -25,
             "percent_level": 37.5,
             "is_initial": true
             }
             ];
             */


        },
        // this must be called as a callback from user action

        onRepeatHearing() {
            if ( ! this.showInitialButton) {
                this.playDigitRound(true, true, false);
                this.exampleAttempts.attempts++;
            }
        },
        handlePretestApproved() {
            // uikit emits closed immediatly. lame. we have to account for this.
            if (this.stage === 'EXAMPLE_ATTEMPTS') {
                this.stage = 'TEST'
            }
        },
        playExample() {
            this.playDigitRound(true, true, true);
            this.exampleAttempts.showInitialButton = false;
            this.exampleAttempts.attempts++
        },
        handleKeyboardInput(e) {
            //   console.log('keyboard input', e);

            let numInput     = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
            let inputKey     = e.key; //1-0, 'Enter', 'Backspace','delete
            let inputKeyCode = e.keyCode;

            if (numInput.includes(inputKey)) {
                this.userInputGiven(inputKey);
            }

            if (inputKey === 'Enter') {
                this.userInputSubmitted();
            }

            if (inputKey === 'Delete' || inputKey === 'Backspace') {
                this.deleteLastUserInput();
            }
        },
        async runNextRound() {
            if ( ! this.isRoundComplete) {
                return true;
            }
            this.isReplaying = false;
            this.lastUserInputSubmitHandled = false;
            this.isRoundComplete = false;
            this.resetUserInput();

            this.playDigitRound();
        },
        async showStepFeedbackReplay () {
            this.isReplaying = true;
            const oldInputArray = this.lastUserInput.split('');
            let i = 0;
            for (const char of oldInputArray) {
                i++;
                await utilities.wait(this.digitInterval);

                this['playIndicator'+i] = true;
                this.userInput = this.userInput + char;

                await utilities.wait(800);

                this.playIndicator1 = false;
                this.playIndicator2 = false;
                this.playIndicator3 = false;
            }
        },
        getAnswerDigitByIndex(index) {
            return this[`digit${index}`];
        },
        async resetButtonHandler() {
            this.stopNoise();
            this.reset();
            this.stage = 'SUBJECT_DETAILS';
            await utilities.wait(100);
            window.location.reload();
        }

    },
    beforeUnmount() {
        this.stopNoise();
        this.reset();
        this.stage = 'SUBJECT_DETAILS';

        //this.reset();
        if (window) {
            window.removeEventListener('keyup', this.handleKeyboardInput)
        }
    },
    created() {

        //   this.reset();
    },
    mounted() {
        if (this.$store.getters['testA/visited'] === true) {
            this.$store.commit('testA/setVisited', false);
            if (window) {
                window.location.reload();
            }
        }

        this.$store.commit('testA/setVisited', true);

        this.$store.commit('subject/resetSubject');
        this.stage = 'SUBJECT_DETAILS';
        this.reset();
        this.preloadAudio();

        if (window) {
            window.addEventListener('keyup', this.handleKeyboardInput)
        }
    },
    watch: {
        stage(newValue, oldValue) {
            if (this.stage === 'EXAMPLE_ATTEMPTS' || this.stage === 'TEST') {
                this.playNoise();
            } else {
                this.stopNoise();
            }

            if (this.stage === 'TEST') {
                this.startTest();
            }
        },
        userInput(newValue, oldValue) {

        }
    },


}
</script>

<style scoped lang="scss">
@use 'sass:math';

.test-a {
    margin-bottom : 70px;
}

.fade-enter-active,
.fade-leave-active {
    transition-duration        : 0.3s;
    transition-property        : height, opacity;
    transition-timing-function : ease;
    overflow                   : hidden;
}

.fade-enter,
.fade-leave-active {
    opacity : 0
}

.calibration {
    margin : auto;
}

.answer-display {
    max-width  : 300px;
    margin     : 0 auto;
}

.user-input-field, .play-indicator-field {
    max-width  : 300px;
    margin     : 0 auto;
    background : transparent;
    font-size  : 30px;
    text-align : center;
    direction  : ltr;

    .input-content {
        flex  : 0 0 20%;
        // padding: 0 20px;
        color : var(--c-inverse); //$global-secondary-background; //#######
    }

    .digit-feedback {
        top    : 100%;
        height : 5px;
        width  : 100%;

        &__inner {
            height : 5px;
        }
    }
}

.play-indicator-field {
    display         : flex;
    align-items     : center;
    justify-content : center;

}

.indicator-slot {
    display : flex;
    justify-content: center;
    height  : 36px;
    width   : 20%;
    flex: 0 0 20%;

    .uk-icon {
        display         : flex;
        align-items     : center;
        justify-content : center;
        height          : 100%;
        width           : 100%;
        margin          : 0;
    }
}

$input-char-size    : 65px;
$input-char-margin  : 12px;
$ui-container-width : $input-char-size*3 +  $input-char-margin*6;

.message-container {
    overflow : hidden;
}

.message-area {
    position   : relative;
    right      : 0;
    top        : 0;
    width      : 2*$ui-container-width;
    max-width  : 2*$ui-container-width;
    height     : auto;
    padding    : 10px 15px;
    box-sizing : border-box;
}

.test-a-input-ui {
    position : relative;
}

.digit-input-ui {
    margin          : 20px auto 10px;
    display         : flex;
    justify-content : center;
    flex-wrap       : wrap;
    width           : $ui-container-width;

    // rtl numpad should look the same as ltr
    [dir=rtl] & {
        flex-direction : row-reverse;
    }

    .input-ui-char {
        display         : flex;
        align-items     : center;
        justify-content : center;
        height          : $input-char-size;
        width           : $input-char-size;
        margin          : math.div($input-char-margin, 2) $input-char-margin;
        font-size       : math.div($input-char-size, 2.1); // $input-char-size/2.1;
        font-weight     : bold;
        cursor          : pointer;
        transition      : all 150ms;

        &:hover {
            opacity : 0.9;
        }

        &:active {
            opacity : 0.7;
        }
    }

    .user-input-reset, .user-input-submit {
        .uk-icon {

        }
    }

    .user-input-reset {
        background : var(--c-error); //$global-danger-background;//#######
        order      : 89;
    }

    .user-input-submit {
        order : 91;
    }
}

.html-log-list {
    direction  : ltr;
    text-align : left;

    li {
        margin-bottom : 20px;
    }
}

.html-log-data {
    display      : inline-block;
    margin-right : 10px;
    width        : 150px;
}

.button-export {
    min-width : 150px;
}

.noise-bar {
    position    : fixed;
    top         : 0;
    right       : 0;
    width       : 100%;
    background  : red;
    color       : white;
    height      : 35px;
    z-index     : 20;
    font-size   : 20px;
    text-align  : center;
    font-weight : bold;
}
</style>
