<template>
  <div class="mister">
    <h1>This is an APP OVERRIDE - OVERRIDE mister page</h1>
  </div>
</template>

<script>
export default {

}
</script>
