<template>
    <subject-details></subject-details>
</template>

<script>
import SubjectDetails from "@/client/applications/hear/components/SubjectDetails.vue";
export default {
    data () {
        return {};
    },
    layout    : 'default',
    meta: 'hear.pageMeta.detailsTitle',
    components: {
        SubjectDetails : SubjectDetails
    }
}
</script>

<style scoped lang="scss">

</style>
