import {watchEffect} from 'vue';

/**
 * When a route meta has requiresGuest, will redirect logged in users based on store/auth module
 * Will hang and wait, if the auth status is not determined.
 *
 * If route meta has requiresGuestRedirect property - will redirect there
 * Otherwise, will redirect based on app config - config.router.requiresGuestRedirect
 * Finally, if both missing, will redirect to '/' as a last resort.
 * @param router
 * @returns {{handler: handler, routerMethod: string}}
 */
export default (router) => {
    return {
        routerMethod: 'saffronBus',
        handler: async (to, from, context) => {


            //; do async stuff
            /*
                console.log('bogle  is waiting');
            return utilities.wait(2000).then(() => {
                console.log('waiting is done');
                return true;
            });
            */

            // return a redirect
           // return {name: 'loyalty'};
            // block navigation
           //return false;

        }
    }
}
