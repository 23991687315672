<template>
  <div>
    <h3>{{ translate('hear.subject.detailsTitle') }}</h3>
    <base-form :config="formConfig"
               v-model="localSubjectData"
               @form:submitSuccess="saveSubjectData()" class="uk-grid-small"> </base-form>
  </div>
</template>

<script>
  export default {
    props: {
        submitButtonText: [String, Boolean],
        notifyAboutSuccess: {
            type: Boolean,
            default: true,
        },
    },
    layout: 'default',
    data: function () {
      return {
        localSubjectData : {},
        validation : {},
        nonAutoFields : ['sex'],
          formConfig : {
            fields: {
                name: {
                    name: 'name',
                    label : 'hear.subject.subjectNameLabel',
                    validation: {
                        required: {}
                    }
                },
                age: {
                    name: 'age',
                    label : 'hear.subject.subjectAgeLabel',
                    validation: {
                        required: {},
                        integer: {},
                    }
                },
                sex: {
                    name: 'sex',
                    label : 'hear.subject.subjectSexLabel',
                    type: 'radio',
                    list: [
                        {value: 'male', label: 'hear.subject.subjectSexMaleLabel'},
                        {value: 'female', label: 'hear.subject.subjectSexFemaleLabel'},
                    ],
                    validation: {
                        required: {},
                    }
                }
            },
            buttons: {
                  clear: {
                      type : 'reset',
                      text : 'hear.subject.subjectFormReset',
                      theme: 'inverse',
                      group: 'buttons',
                  },
                submit: {
                    type : 'submit',
                    label : 'core.submit',
                    group: 'buttons',
                },

              },
            groups: {
                buttons: {
                    component: 'block',
                    props: {
                        class: 'flex gap-m'
                    }
                }
              },
            formHandling: {
               clientOnly: true,
            }
          },
      };
    },
    created () {
      this.resetSubjectDataForm(false);
    },
    computed : {

    },
    methods : {

      saveSubjectData : function () {
        this.$emit('subject-details-submitted');
        this.$store.commit('subject/updateSubjectData', this.localSubjectData);
        this.$emit('subject-details-saved');
        if(this.notifyAboutSuccess) {
            this.$s.ui.notification('core.successGeneric', 'success');
        }

      },
      resetSubjectDataForm : function (notify = false) {
        this.localSubjectData = Object.assign({},this.$store.getters['subject/subjectData']);

        this.validation = {};
        Object.keys(this.localSubjectData).forEach((key, value) => {
          this.validation[key] = false;
        });

        if (notify) {
            this.$s.ui.notification('hear.subject.changesCanceled');
        }

      },
      isFieldAuto : function(name) {
        return this.nonAutoFields.includes(name);
      }
    }



  }
</script>
<style scoped lang="scss">
  .submit-wrapper button {
    cursor: pointer;
  }

  .button-submit {
    min-width: 300px;

    @media screen and (max-width: 500px) {
      min-width: 0px;
      flex: 0 0  100%;
    }
  }
</style>
