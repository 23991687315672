import asyncFactory from '@/client/extensions/modules/asyncComponentFactory.js'

export default [
    {
        path: '/test',
        name: 'Test',
        component: asyncFactory('views/Test.vue')
    }
];


