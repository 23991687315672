<template>
    <div class="width-350 max-width-450">
        <div :style="logoStyle"
             class="logo width-expand center text-center margin-m-bottom"></div>
        <span class="center text-center block margin-xl-bottom">{{translate('core.meta.siteName')}}</span>

        <panel class="margin-xl-bottom" theme="">
            <div class="flex gap-m flex-middle flex-center ">
                <div class="icon-container flex-center text-center">
                    <icon icon="warning-triangle" size="3" theme="error"></icon>
                </div>

                <div class="text-content">
                    <h6 class="block margin-remove text-l semibold text-semibold">
                        {{ translate('core.errorPage.title') }}</h6>
                    <p class="block margin-remove">{{ translate('core.errorPage.errorExplain') }}</p>
                </div>
            </div>

        </panel>

        <form-button :full-width="true" @click="$router.push({name: 'index'})"
                     icon="arrow-inline-start"
                     text="core.errorPage.backCta"></form-button>

        <panel class="margin-2xl-top panel--tight" theme="gray-1" >
            <div class="center text-center">
                <strong class="block text-center">{{ translate('core.errorPage.technicalInformationTitle') }}</strong>
            </div>
            <div class="ltr">
                <div v-if="technicalInformationHtml" v-html="technicalInformationHtml"></div>
                <span  v-else>{{translate('core.errorPage.noErrorInfo')}}</span>

                <div class="trace margin-l-top"
                     v-if="errorAsString">
                    <form-button size="small"
                                 @click="traceExpanded = true"
                                 text="core.errorPage.showTrace"></form-button>
                    <pre class="margin-remove-bottom margin-m-top ltr"
                        v-if="errorAsString && traceExpanded" >
{{errorAsString}}
                    </pre>
                </div>
            </div>

        </panel>


    </div>
</template>

<script>
export default {
    props   : {
        technicalInformationHtml: {
            type   : [Boolean, String],
            default: false, //'error information error 500 something'
        },
        logoSrc             : {
            type   : [String, Boolean],
            default: 'core/images/logos/main.png'
        },
        errorAsString: {
            default: false,
        }
    },
    data    : function () {
        return {
            traceExpanded : false,
        };
    },
    computed: {
        logoStyle() {
            if ( ! this.logoSrc) {
                return {};
            }

            let url = utilities.requireAsset(this.logoSrc);
            return {
                'backgroundImage': "url('" + url + "')",
                'background-size' : 'contain',
                'background-position': 'center center',
                'background-repeat': 'no-repeat',
                'height'         : '80px',
                'width'          : '100%',

            };
        },
    },
    layout  : {
        component: 'boxy',
        params   : {'backgroundImage': 'core/images/boxy/default-background.webp'}
    },
    meta: 'core.errorPage.pageTitle'

}
</script>

<style scoped lang="scss">

</style>
