import asyncFactory from '@/client/extensions/modules/asyncComponentFactory.js'

export default [
    {
        path: '/:pathMatch(.*)*',
        component:  asyncFactory('views/PageNotFound.vue'),
        meta : {
            'layout' : 'none',
            'layoutParams': {
                'wrapMainContent': true,
                'showTopBanners' : true,
            },
        },
    }
];


