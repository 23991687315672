<template>

    <Skeleton
        :class="skeletonClass"
              template="form"
              :fitHeight="false"
              :defaultHeight="skeletonHeight"
              :count="expectedFields"
              :contentReady="asyncStatus.asyncDataReady">

        <form class="form"
              :class="formClass"
              v-if="asyncStatus.asyncDataReady"
              :method="formMethod"
              :disabled="formDisabled"
              :action="action"
              @submit.prevent="handleSubmit()"
              @reset.prevent="clear(), untouchAll()">

            <alert
                class="form-alert margin-l-bottom"
                v-if="showSubmitError"
                type="danger"

                close-type="event"
                @alert:closing="hideSubmitError">
                {{finalGeneralErrorMessage}}
            </alert>

            <div class="fields"  :class="finalFieldWrapperClass">


                <component
                        v-for="(group, groupKey) in getGroupedFields()"
                        key="groupKey"
                        :is="group.component"
                        v-bind="group.props"
                >

                    <component
                            v-for="(field, fieldIndex) in getGroupFieldsForRendering(group)"
                            :is="field.component"
                            v-bind="{themeStyle: themeStyle, ...field}"
                            :error-display-position="errorDisplayPosition"
                            :key="groupKey+'-field-'+fieldIndex"
                            v-model="formData[field.name]"
                            @focusout="allowFieldErrorDisplay(fieldIndex)"
                            @input="touchInput(fieldIndex)"
                            :validation-pending="isFieldValidationPending(fieldIndex)"
                            :error-message="getFieldValidationErrorMessage(fieldIndex)"
                    ></component>


                </component>
                <slot name="afterFields"></slot>
            </div>
            <component
                v-for="(group, groupKey) in getGroupedButtons()"
                key="groupKey"
                :is="group.component"
                v-bind="group.props">

                <component
                    v-for="(button, buttonIndex) in group.buttons"
                    :is="button.component"
                    :disabled="buttonsDisabled"
                    :loading="buttonsLoadingStatus[groupKey][buttonIndex]"
                    :key="groupKey+'-button-'+buttonIndex"
                    v-bind="getButtonConfig(buttonIndex)">
                    {{safeTranslate(button.text)}}
                </component>
            </component>
            <slot name="afterButtons"></slot>

            <div class="recaptcha-information text-center">
                <div v-show="preflightTypes.includes('recaptcha')"
                     class="recaptcha-text"
                     style="font-size: var(--font-min); margin-top: var(--margin-m);">
                    <span>{{ forceTranslate('core.form.recaptcha.text') }}</span>,
                    <a target="_blank" href="https://policies.google.com/privacy">{{ forceTranslate('core.form.recaptcha.privacyPolicy') }}</a>
                    {{ forceTranslate('core.form.recaptcha.and') }}
                    <a target="_blank" href="https://policies.google.com/terms">{{ forceTranslate('core.form.recaptcha.tos') }}</a>
                    {{ forceTranslate('core.form.recaptcha.apply') }}
                </div>
            </div>
            <spinner v-if="showLoadingOverlay"
                     :text="overlaySpinnerText"
                     overlay="absolute"
                     :show="true"></spinner>
        </form>

    </Skeleton>

</template>

<script>
    const _ = require('lodash/object');

    import useValidation from "@/client/extensions/composition/useValidation";
    import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
    import useRecaptcha  from '@/client/extensions/composition/useRecaptcha.js';
    import useVuelidate from '@vuelidate/core'

    import { reactive } from 'vue';

    import BaseFormMixin from '@/client/extensions/mixins/baseComponents/form/baseForm.js'

    export default {
        mixins: [BaseFormMixin],
        setup (props, context) {
            let {asyncOps, asyncOpsReady, asyncStatus,} = asyncOperations(props);
            let {isCaptchaReady, executeCaptcha} = useRecaptcha();
            return {
                v$ : useVuelidate(),
                asyncOps, asyncOpsReady, asyncStatus,
                ...useValidation(props),
                isCaptchaReady,
                executeCaptcha
            }
        },
        props: {
            controlGroupHeight: {
                type: [Number],
                default: 80
            },
        },
        data: function () {
            return {
                primaryButtonDefaultClass: 'button--lead',
            }
        },
        computed: {
            finalFieldWrapperClass () {
                let result = this.fieldWrapperClass;
                if ( ! this.fieldWrapperMargin) {
                    return result;
                }

                if ( this.fieldWrapperMargin === true) {
                    return  result = result + ' ' + 'margin-l-bottom';
                }
                result = result + ' ' + 'margin-'+this.fieldWrapperMargin+'-bottom';


                return result;
            },

            skeletonHeight () {
                // 85 px for each form field, + another such unit for the button
                return (this.expectedFields +1) * this.controlGroupHeight;
            },

            skeletonClass () {
                if (this.class) {
                    return 'form-skeleton form-skeleton--'+this.class;
                }

                return 'form-skeleton';
            },

            formClass () {
                let className = '';
                if (this.class) {
                    className = 'form '+this.class;
                }

                if (this.errorDisplayPosition === 'absolute') {
                    className = className + ' form--errors-absolute'
                }

                className = className + ' form--style-'+this.themeStyle;

                return className;
            },

        },
        watch: {
            finalConfig(newVal) {
               // console.log('watch', newVal);
            }
        },
        validations () {

            return {
                formData : this.getValidationRules()
            }
        },
        serverPrefetch () {
            return this.getSSRPrefetchPromise();
        },
    }
</script>

<style scoped lang="scss">


</style>


<style lang="scss">
// NON SCOPED STYLE - we can hide recaptcha badge because we handle the legal stuff ourselves
.grecaptcha-badge { visibility: hidden; }
</style>

