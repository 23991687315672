<template>
  <div class="mister">
    <h1>This is an OVERRIDE ACTIVE APP IGNORE PAGE</h1>
  </div>
</template>

<script>
export default {

}
</script>
