let validators = {};
const validatorFactories = {};
// todo: validation overrides? or at least app validation imports?



// look for app validation /validation overrides
let context = require.context('@/client/applications/', true, /^\.\/.*\/extensions\/validation\/validators\/.*\.js$/);
context.keys().forEach(key => {
    let exploded = key.split('/');
    let name     = exploded.pop().replace('.js', '');
    validators[name] = context(key).default || context(key);
});


context = require.context('@/client/extensions/validation/validators', true, /\.js/);
context.keys().forEach(key => {
    let exploded = key.split('/');
    let name     = exploded.pop().replace('.js', '');

    if (validators.hasOwnProperty(name)) {
        return true;
    }

    validators[name] = context(key).default || context(key);
});


context = require.context('@/client/applications/', true, /^\.\/.*\/extensions\/validation\/validatorFactories\/.*\.js$/);
context.keys().forEach(key => {
    let exploded = key.split('/');
    let name     = exploded.pop().replace('.js', '');
    validatorFactories[name] = context(key).default || context(key);
});


context = require.context('@/client/extensions/validation/validatorFactories', true, /\.js/);
context.keys().forEach(key => {
    let exploded = key.split('/');
    let name     = exploded.pop().replace('.js', '');

    if (validatorFactories.hasOwnProperty(name)) {
        return true;
    }

    validatorFactories[name] = context(key).default || context(key);
});


export   {
    validators, validatorFactories
}
