import asyncFactory from '@/client/extensions/modules/asyncComponentFactory.js'


export default [
    {
        path: '/admin/entity',
        component: asyncFactory('views/admin/entity/Entity.vue'),
        name: 'entity',
        meta : {
            layout : 'adminDashboard',
            requiresAdmin : true
        },
        children: [
            {
                path: '',
                name: 'entity-dashboard',
                alias: ['dashboard'],
                component: asyncFactory('views/admin/entity/EntityDashboard.vue'),
            },
            {
                path: ':entityType',
                redirect: to => {
                    return { name: 'entity-list', params: to.params }
                },
            },
            {
                path: ':entityType/list',
                name: 'entity-list',
                component: asyncFactory('views/admin/entity/List.vue'),
                props: true,
            },
            {
                path: ':entityType/create',
                name: 'entity-create',
                component: asyncFactory('views/admin/entity/Create.vue'),
                props: true,
            },
            {
                path: ':entityType/edit/:entityId',
                name: 'entity-edit',
                component: asyncFactory('views/admin/entity/Edit.vue'),
                props: true,
            }
        ]

    },



];
