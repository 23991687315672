<template>
    <div>
        <div v-if="! isAdmin">
            <h1 class="text-center margin-2xl-bottom">{{ translateTitleCase('hear.admin.loginTitle') }}</h1>
            <!--
            <base-form :config="formConfig" v-model="localFormData" @form:submit-success="adminLogin"></base-form>
-->
            <base-form
                action="/token/refresh/userPassword"
                method="post"
                :expectedFields="2"
                v-model="loginData"
                :config="loginFormConfig"
                :generalErrorMessage="'user.loginFailedGenetic'"
                @form:submitSuccess="loginSuccessHandler"

            ></base-form>
        </div>

        <div v-if="isAdmin">
            <h1 class="text-center margin-2xl-bottom">{{ translateTitleCase('hear.admin.logoutTitle') }}</h1>
            <div class="margin-m-vertical container container-xs">
                <form-button @click="adminLogout()"
                             :full-width="true"
                             icon="arrow-logout-inline-start"
                             class="margin-s-bottom"
                             text="hear.admin.logoutCta"></form-button>

                <form-button @click="clearCookies()"
                             :full-width="true"
                             icon="trash"
                             theme="gray"
                             text="hear.admin.clearCookiesCta"></form-button>
            </div>


        </div>
    </div>
</template>

<script>

import asyncOperations from "@/client/extensions/composition/asyncOperations";
import {useStore} from "vuex";
import WeHorizontalRuler from "@/client/applications/hear/components/base/frameworks/we/html/WeHorizontalRuler";

export default {
    setup(props) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        return {asyncOps, asyncOpsReady, asyncStatus};
    },
    layout    : 'default',
    middleware: {

    },
    components: {
        WeHorizontalRuler

    },
    data : function () {
        return {

            localFormData: {
                pin: '',
            },
            loginData: {
                email: null,
                password: null,
            },
            loginFormConfig : {
                fields: {
                    email: {
                        name: 'email',
                        type: 'email',// must have default
                        label : 'core.form.email',
                        placeholder : 'core.form.placeholders.email',
                        icon: 'mail',
                        validation: {
                            'required': {},
                            'email' : {},
                        },
                    },
                    password: {
                        name: 'password',
                        type: 'password',// must have default
                        label : 'core.form.password',
                        placeholder : 'core.form.placeholders.password',
                        icon: 'lock',
                        validation: {
                            'required' : {},
                            'minLength': {
                                params: {min:6}
                            }
                        },
                    },
                }
            },
            formConfig: {
                fields: {
                    pin: {
                        name: 'pin',
                        label: 'hear.admin.pinFieldLabel',
                        validation: {
                            required: {}
                        }
                    }
                },
                formHandling: {
                    clientOnly: true,
                }
            }
        }
    },
    methods :  {
        adminLogin  () {
            // check pin or error
            if ( this.$store.getters['admin/isPinCorrect'](this.localFormData.pin)) {
                this.$store.commit('admin/setIsAdmin', true);
                this.$s.ui.notification('hear.admin.successfulLogin', 'success');
            } else {
                this.$s.ui.notification('hear.admin.badCode', 'error');
            }
            // login
        },
        async adminLogout () {
           // this.$store.commit('admin/setIsAdmin', false);
            await this.$store.commit('user/logout');
            this.$s.ui.notification('hear.admin.successfulLogout');
        },
        async loginSuccessHandler(eventData) {

            this.$store.commit('user/setToken', eventData.resultData.jwt);
            this.$store.commit('user/setRefreshToken', eventData.resultData.refreshToken);

            this.$store.commit('user/setRefreshTokenExpires', eventData.resultData.refreshTokenExpires);

            let result = await this.$store.dispatch('user/updateUserProfile');

            if (result === false) {
                this.$saffron.ui.notification(this.safeTranslate('user.fetchProfileException'), 'danger');
                return false;
            }

            if ( ! this.$store.getters['user/profile'].isAdmin) {
                // logout
                await this.$store.commit('user/logout');
                this.$saffron.ui.notification(this.safeTranslate('hear.admin.onlyAdminLoginAllowed'), 'danger');

            }
            console.log(this.$store.getters['user/profile']);

        },
        clearCookies () {
           this.$store.commit('testA/clearSettings');
        }

    },
    computed : {
        isAdmin: {
            get: function () {
                return this.$store.getters['user/isAdmin'];
            },
            set: function () {
                throw new Error('Can not set admin via computed.isAdmin in admin login. this isnt the way to do things');
            }
        }
    }

}
</script>

<style lang="scss" scoped>

.logo-container {
    $logo-size: 75px;
    height:  $logo-size;
    width:  $logo-size;
    flex: 0 0 $logo-size;
    margin: 0 auto var(--margin-xl) auto;

    @media screen and (max-width: 767px) {
        $logo-size: 50px;
        height:  $logo-size;
        width:  $logo-size;
        flex: 0 0 $logo-size;
        margin-bottom: var(--margin-m);
    }
    img {
        height: 100%;
        width: 100%;
    }
}
</style>



