
<template>
    <div>
        <h1 class="text-center ">{{translate('hear.settings.mainTitle')}}</h1>
        <h3 class="text-center margin-2xl-bottom">
            <block class="flex middle flex-center flex-middle gap m">
                <form-button @click="doShowSettingsModal()" class="button--slim" :show-slot="false" theme="gray-5" icon="settings"></form-button>
                <span>{{translate('hear.settingsDIN.settingsTitle')}}</span>
            </block>
        </h3>

       <modal :show="showSettingsManagerModal"
              :title="false"
              ref="settingsModal">
           <test-settings-manager
               :subject="'testa'"
               :settings-mutation="'testA/updateSettings'"
               :settings-getter="'testA/settings'"
               @settingsManager:settingsLoaded="showSettingsManagerModal = false"
           ></test-settings-manager>
           <template v-slot:footer="{modal}">
               <div class="flex width-expand flex-center ">
                   <form-button class="width-expand max-width-300" theme="gray-5"
                       @click="doHideSettingsModal">{{ translate('hear.settingsManager.closeSettingsManager') }}</form-button>
               </div>
           </template>
       </modal>

        <base-form :config="formConfig" v-model="localSettings" @form:submit-success="saveSettings()"></base-form>

           <form-button :full-width="true"
                        class="margin-l-top"
                        theme="gray"
                        @click="modal.close()">{{translate('hear.settings.reset')}}</form-button>


    </div>
</template>

<script>
import testSettingsManager from '@/client/applications/hear/components/TestSettingsManager.vue';
export default {
    props: {},
    data: function () {
        // lets define special config to some of the fields
        let fieldOverloads = {
            debugMode: {
                type: 'radio',
                list: [
                    {'value': false, label: 'hear.settingsDIN.booleanOff',},
                    {'value': true, label: 'hear.settingsDIN.booleanOn',}
                ],
            },
            skipToTest: {
                type: 'radio',
                list: [
                    {'value': false, label: 'core.no',},
                    {'value': true, label: 'core.yes',}
                ],
            },
            pureTone: {
                type: 'radio',
                list: [
                    {'value': false, label: 'core.no',},
                    {'value': true, label: 'core.yes',}
                ],
            },
            stepFeedback: {
                type: 'radio',
                list: [
                    {'value': false, label: 'core.no',},
                    {'value': true, label: 'core.yes',}
                ],
            },
            stepReplayFeedback: {
                type: 'radio',
                list: [
                    {'value': false, label: 'core.no',},
                    {'value': true, label: 'core.yes',}
                ],
            },
            digitFeedback: {
                type: 'radio',
                list: [
                    {'value': false, label: 'core.no',},
                    {'value': true, label: 'core.yes',}
                ],
            },
            nextRoundAutoPlay: {
                type: 'radio',
                list: [
                    {'value': false, label: 'core.no',},
                    {'value': true, label: 'core.yes',}
                ],
            },
            numDigits: {
                type: 'radio',
                list: [
                    {'value': 2, label: '2',},
                    {'value': 3, label: '3',}
                ],
                translateList: false,
            }
        };

        // make generic fields for all settings
        let fields = {};
        for (const [key, value] of Object.entries(this.$store.getters['testA/settings'])){
            fields[key] = {
                name: key,
                label: 'hear.settingsDIN.' + key,
                group: 'mainFields',
            }
        }

        for (const [key, overload] of Object.entries(fieldOverloads)) {
            if (fields[key]) {
                fields[key] = {...fields[key], ...overload};
            }
        }

        return {
            localSettings : {},
            formConfig: {
                fields: fields,
                groups: {
                    mainFields: {
                        component: 'FormFieldSet',
                        props: {
                            maxFieldsPerRow: 3,
                            plainStyle: true,
                        }
                    }
                },
                formHandling: {
                    clientOnly: true,
                }
            },
            showSettingsManagerModal : false,

        };
    },
    layout: 'default',
    middleware: {
        requireAdmin : {},
    },
    components: {
        testSettingsManager,
    },
    created () {
        this.pullSettingsFromStore(false);
    },
    computed : {
        storeSettings() {
            return this.$store.getters['testA/settings'];
        }
    },
    watch : {
        storeSettings : {
            'deep': true,
            handler(newVal) {
                this.pullSettingsFromStore();
            }
        },
    },
    methods : {
        doShowSettingsModal () {
          this.$refs.settingsModal.open();
        },
        doHideSettingsModal () {
            this.$refs.settingsModal.close();
        },
        saveSettings : function () {
            this.$store.commit('testA/updateSettings', this.localSettings);
            this.pullSettingsFromStore(false);
            this.$s.ui.notification('core.successGeneric', 'success');

        },
        pullSettingsFromStore : function (notify = false) {
            this.localSettings = Object.assign({}, this.storeSettings);
            if (notify) {
                this.$s.ui.notification('hear.settings.changesAborted', 'info');
            }

        },
        resetSettings() {
            this.$store.commit('testA/resetSettings')
            this.localSettings = Object.assign({}, this.$store.getters['testA/settings']);
            this.$s.ui.notification('hear.settings.settingsReset', 'success');

        }
    },
    mounted() {

    }

}
</script>

<style scoped lang="scss">

</style>
