<template>
    <div>
        <h1 class="text-center margin-2xl-bottom">{{ translateTitleCase('hear.mainPageTitle') }}</h1>
        <div class="container flex flex-center">
            <form-button
                @click="$router.push({name: 'tests-testa'})"
                class="" size="large" icon-end="chevron-inline-end" text="hear.startCta"></form-button>
        </div>
    </div>
</template>

<script>

import asyncOperations from "@/client/extensions/composition/asyncOperations";
import {useStore} from "vuex";

export default {
    setup(props) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        return {asyncOps, asyncOpsReady, asyncStatus};
    },
    layout    : 'default',
    meta: 'hear.pageMeta.indexTitle',
    middleware: {

    },
    data() {
        return {

        }
    },

    computed: {},
    methods : {
        loginSuccessHandler(result) {
            console.log('login success', result)
        }
    },
    mounted() {},
    watch: {},

}
</script>

<style lang="scss" scoped>

.logo-container {
    $logo-size: 75px;
    height:  $logo-size;
    width:  $logo-size;
    flex: 0 0 $logo-size;
    margin: 0 auto var(--margin-xl) auto;

    @media screen and (max-width: 767px) {
        $logo-size: 50px;
        height:  $logo-size;
        width:  $logo-size;
        flex: 0 0 $logo-size;
        margin-bottom: var(--margin-m);
    }
    img {
        height: 100%;
        width: 100%;
    }
}
</style>



