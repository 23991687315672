import {watchEffect} from 'vue';

/**
 * When a route meta has requiresUser, will redirect guests based on store/auth module
 * Will hang and wait, if the auth status is not determined.
 *
 * If route meta has requiresUserRedirect property - will redirect there
 * Otherwise, will redirect based on app config - config.router.requiresUserRedirect
 * Finally, if both missing, will redirect to '/' as a last resort.
 * @param router
 * @returns {{handler: handler, routerMethod: string}}
 */
export default (router) => {
    return {
        routerMethod: 'beforeEach',
        handler: async (to, from) => {
            let redirect;

            if ( ! to.meta || ! to.meta.requiresUser) {
                return true;
            }

            // ok we can only allow logged in users here.

            // if token validity is unknown, wait until this changes
            if (router.$store.getters['user/isTokenValid'] === null) {
                await new Promise((resolve) => {
                   watchEffect( () => {
                       if (router.$store.getters['user/isTokenValid'] !== null && ! router.$store.getters['user/isValidatingToken']) {
                           resolve();
                       }
                   });
                });
            }

            // if the token is valid, this is not a guest and this is not allowed
            if ( ! router.$store.getters['user/isTokenValid']) {
                redirect = to.meta.requiresUserRedirect || config.router.requiresUserRedirect || '/';
                router.push(redirect);
                return false;
            } else {
                return true;
            }


        }
    }
}
