import Home from '@/client/views/Home.vue'
import asyncFactory from '@/client/extensions/modules/asyncComponentFactory.js'


export default [
    {
        path: '/general/404',
        name: '404',
        component: asyncFactory('views/general/PageNotFound.vue'),
        props: {
            logoSrc : 'images/logos/main.svg',
            loginRedirect: {name: 'selfcare-dashboard'}
        },
        meta : {
            'layout' : 'boxy',
            'layoutParams': {
                'backgroundImage': 'core/images/user/login/boxy_background_2.jpg'
            },
            //    requiresGuest : true,
        }
    },
    {
        path: '/general/unauthorised',
        name: 'unauthorised',
        component: asyncFactory('views/general/Unauthorised.vue'),
        props: {
            logoSrc : 'images/logos/main.svg',
            loginRedirect: {name: 'selfcare-dashboard'}
        },
        meta : {
            'layout' : 'boxy',
            'layoutParams': {
                'backgroundImage': 'core/images/user/login/boxy_background_2.jpg'
            },
            //    requiresGuest : true,
        }
    },



];
